.Menu
{
  flex-direction: column;
  position: fixed;
  z-index: 4;
  width: 360px;
  height: 100%;
  border: 2px solid white;
  margin-left: 50px;
  margin-top: 5vh;
  background-color: #000000;
  border-radius: 20px;
  box-shadow: 4px 4px 0px 4px white;
  padding: 25px;
  overflow: auto;
}

button{
  border:none;
}


.button-icon{
  background-color: black;
  border: none;
}

.vertical-Container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.horizantal-Container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.checkbox-Container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.icons{
  width: 1.5em;
  margin-right: 25px;
}

.title_margin{
  margin-bottom: 20px;
}

.subTitle_height{
  margin-top: 1em;
  margin-bottom: 20px;

}

#arrow-image{
  background-color: black;
  border: none;
  height: 15px;
  width: auto;
}

#plus_icon{
  background-color: black;
  border: none;
  height: 7px;
  width: auto;
  margin:auto;
}

#collapse {
 animation: rotate-keyframes 1s;
 height: 30vh
}

#header{
  font-size: 2em;
  padding-left:0.5em;
}

#menu-Modules{
  gap: 1em;
  padding: 0.5em;
}

#faraway{
  justify-content: space-between;
}

#subtitleText{
  margin-bottom: 20px;
}
