*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1{
  font-family: "Darker Grotesque", serif;
  font-size: 1.8em;
  color: white;
  font-weight: 800;
  margin-bottom: .25em;
}

h2,a{
  font-family: "Darker Grotesque", serif;
  font-size: 1.2em;
  color: white;
  font-weight: 600;

}

h3{
  font-family: "Darker Grotesque", serif;
  font-size: 1em;
  color: white;
  font-weight: 300;
}

html,
body
{
    overflow: hidden;
}

.experience
{
    position: fixed;
    width: 100vw;
    height: 100vh;
}
