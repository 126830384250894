input{
  background-color: #000;
  color: white;
  border: none;
  font-family: "Darker Grotesque", serif;
  font-size: 1.2em;
  color: white;
  font-weight: 600;
}

input:focus{
  border: none;
  border-bottom: 2px solid white;
  outline:none;
}

.checkBox-div{

}

#textStrike{
  text-decoration: line-through;
}



.App {
  text-align: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.todo-form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

li {
  text-align: initial;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
