.SliderContainer{
  padding:10px;
  margin-top: 
}

#playpauseButton{
  background-color: #000000;
  margin-left:1.5em;
  color: #000000;
}
