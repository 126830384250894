/** Now Playing **/
.now-playing__name {
  font-size: 1em;
  margin-top: 10px;
  color:white;
}
.now-playing__artist {
  margin-bottom: 0.5em;
  color:white;
}
.now-playing__status {
  margin-bottom: .5em;
  color:white;
}
.now-playing__img {
  text-align: right;
  width: 50%;
}
.now-playing__img img {
  max-width: 100vmin;
  width: 100%;
}

.now-playing__side {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  margin-left: 5%;
  width: 60%;
  height: 130px;
}
/** Progress **/
.progress {
    border: 1px solid #eee;
    height: 6px;
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 20px;
}

.progress__bar {
    background-color: #eee;
    height: 4px;
}



.hidden {
  display: none;
}

/** Buttons **/

.btn {
  text-decoration: none;
  background-color: transparent;
  border-radius: 2em;
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 1em;
  padding: 0.5em 1.0em;
  text-transform: uppercase;
  transition: all 0.25s ease;
  text-align:center;
}

.btn:hover {
  background: #1ecd97;
  color: #333;
  border: 0.2em solid #1ecd97;
}

.btn--login {
  margin: 0 auto;
}

.main-wrapper {
  align-items: center;
  display: flex;
  margin: 0;
  justify-content: center;
  width: 100%;
  z-index: 1;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.main-container {
  flex: 1;
}

#song_name{
  font-weight: 600;
  line-height: 1;

}

#SpotifyLogin{
  width:100%;
  margin-top: 30px;

}
